import { type Config } from 'tailwindcss'

export const extendedTheme = {
	backgroundImage: {
		'gradient-custom': 'var(--gradientmain)',
		'gradient-topbot': 'var(--gradientcard)',
	},
	colors: {
		secondcolor: 'var(--secondcolor)',
		inputbg: 'var(--input-bg)',
		navhover: 'var(--navhover)',
		navborder: 'var(--navborder)',
		border: 'var(--border)',
		input: {
			DEFAULT: 'var(--input)',
			invalid: 'var(--input-invalid)',
		},
		ring: {
			DEFAULT: 'var(--ring)',
			invalid: 'var(--foreground-destructive)',
		},
		header: 'var(--header)',
		foreheader: {
			DEFAULT: 'var(--foreheader)',
			destructive: 'var(--foreground-destructive)',
		},
		footer: 'var(--footer)',
		forefooter: {
			DEFAULT: 'var(--forefooter)',
		},
		sidebar: 'var(--sidebar)',
		foresidebar: {
			DEFAULT: 'var(--foresidebar)',
		},
		background: 'var(--background)',
		foreground: {
			DEFAULT: 'var(--foreground)',
			destructive: 'var(--foreground-destructive)',
		},
		primary: {
			DEFAULT: 'var(--primary)',
			foreground: 'var(--primary-foreground)',
		},
		secondary: {
			DEFAULT: 'var(--secondary)',
			foreground: 'var(--secondary-foreground)',
		},
		destructive: {
			DEFAULT: 'var(--destructive)',
			foreground: 'var(--destructive-foreground)',
		},
		muted: {
			DEFAULT: 'var(--muted)',
			foreground: 'var(--muted-foreground)',
		},
		accent: {
			DEFAULT: 'var(--accent)',
			foreground: 'var(--accent-foreground)',
		},
		popover: {
			DEFAULT: 'var(--popover)',
			foreground: 'var(--popover-foreground)',
		},
		card: {
			DEFAULT: 'var(--card)',
			foreground: 'var(--card-foreground)',
		},
	},
	borderColor: {
		DEFAULT: 'var(--border)',
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['3.2rem', { lineHeight: '3.75rem', fontWeight: '700' }],
		h1: ['1.4rem', { lineHeight: '1.8rem', fontWeight: '600' }],
		h2: ['1.2rem', { lineHeight: '1.4rem', fontWeight: '600' }],
		h3: ['1.1rem', { lineHeight: '1.2rem', fontWeight: '600' }],
		h4: ['1.0rem', { lineHeight: '1.2rem', fontWeight: '600' }],
		h5: ['1.0rem', { lineHeight: '1.2rem', fontWeight: '600' }],
		h6: ['1.0rem', { lineHeight: '1.2rem', fontWeight: '600' }],
		label2: ['11px', { lineHeight: '12px', fontWeight: '500' }],
		note3: ['10px', { lineHeight: '16px', fontWeight: '500' }],

		/** 32px size / 36px high / normal */
		'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
		/** 28px size / 36px high / normal */
		'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
		/** 24px size / 32px high / normal */
		'body-lg': ['1.5rem', { lineHeight: '2rem' }],
		/** 20px size / 28px high / normal */
		'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
		/** 16px size / 20px high / normal */
		'body-sm': ['1rem', { lineHeight: '1.25rem' }],
		/** 14px size / 18px high / normal */
		'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
		/** 12px size / 16px high / normal */
		'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
} satisfies Config['theme']
